@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.groupIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.hamburgerMenu {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-11xl) var(--padding-3xs) 0;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
}
.tabIcons {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboard {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--switch-slidefill);
  text-align: center;
}
.tabDashboard {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-base);
  background-color: transparent;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.dashboard1 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--switch-slidefill);
  text-align: right;
}
.itemGroup,
.tabTours {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.itemGroup {
  gap: var(--gap-5xs);
}
.tabTours {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-9xs) var(--padding-5xs)
    var(--padding-base);
  background-color: var(--color-mediumslateblue-200);
  border-radius: var(--br-9xs) 0 0 var(--br-9xs);
  border-right: 2px solid var(--color-mediumslateblue-100);
  box-sizing: border-box;
  width: 101px;
}
.dashboard2 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.tabInformation {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  gap: var(--gap-5xs);
}
.mobileMenuTours,
.nav,
.tabInformation {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.nav {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-mediumslateblue-300);
  flex-direction: column;
  padding: var(--padding-11xl) 0 0 var(--padding-31xl);
  align-items: flex-end;
  gap: var(--gap-5xs);
}
.mobileMenuTours {
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  width: 320px;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
  opacity: 0;
  max-width: 90%;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
.mobileMenuTours.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-right;
}
