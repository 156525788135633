.portalPopupOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
}

.portalPopupOverlay > div {
  height: 95%;
  width: 95%;
}
