@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --m3-label-large: "DM Sans";
  --components-helper-text: Roboto;

  /* font sizes */
  --m3-label-large-size: 14px;
  --m3-label-small-size: 11px;
  --components-input-text-size: 16px;
  --components-helper-text-size: 12px;
  --font-size-13xl: 32px;
  --components-chip-label-size: 13px;
  --font-size-29xl: 48px;
  --typography-h6-size: 20px;
  --m3-title-large-size: 22px;
  --m3-display-large-size: 57px;

  /* Colors */
  --m3-sys-light-on-primary: #fff;
  --color-mediumslateblue-100: #8252d1;
  --color-mediumslateblue-200: rgba(130, 82, 209, 0.2);
  --color-mediumslateblue-300: rgba(130, 82, 209, 0.1);
  --m3-sys-light-surface1: #fef7ff;
  --m3-sys-light-surface: #fbf8fd;
  --color-ghostwhite-100: #f3eefa;
  --m3-sys-light-outline-variant1: #cac4d0;
  --m3-sys-light-outline-variant: #c6c5d0;
  --avatar-fill: #bdbdbd;
  --m3-sys-light-on-surface-variant1: #49454f;
  --m3-sys-light-on-surface-variant: #45464f;
  --color-darkslategray-100: #323232;
  --switch-knobfill: #fafafa;
  --m3-sys-light-outline: #767680;
  --m3-sys-light-on-surface1: #1d1b20;
  --m3-sys-light-on-surface: #1b1b1f;
  --color-gray-100: rgba(255, 255, 255, 0.16);
  --divider: rgba(0, 0, 0, 0.12);
  --action-selected: rgba(0, 0, 0, 0.08);
  --text-disabled: rgba(0, 0, 0, 0.38);
  --input-outlined-enabledborder: rgba(0, 0, 0, 0.23);
  --text-secondary: rgba(0, 0, 0, 0.6);
  --text-primary: rgba(0, 0, 0, 0.87);
  --switch-slidefill: #000;
  --m3-sys-light-primary1: #6750a4;
  --m3-sys-light-primary: #304393;
  --success-main: #2e7d32;
  --m3-sys-light-surface-container-highest: #e4e1e6;
  --primary-main: #2196f3;
  --color-blueviolet: #9747ff;
  --color-lavender-100: #fbeaff;
  --color-whitesmoke: #f6f6f6;
  --color-brown: #9a1c1c;

  /* Gaps */
  --gap-11xl: 30px;
  --gap-5xs: 8px;
  --gap-base: 16px;
  --gap-3xs: 10px;
  --gap-0: 0px;
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-xl: 20px;
  --gap-6xl: 25px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-31xl: 50px;
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-xs: 12px;
  --padding-sm: 14px;
  --padding-4xs: 9px;
  --padding-xl: 20px;
  --padding-10xs: 3px;
  --padding-9xs: 4px;
  --padding-7xs: 6px;
  --padding-8xs: 5px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-2xs: 11px;
  --padding-21xl: 40px;

  /* border radiuses */
  --br-81xl: 100px;
  --br-xs: 12px;
  --br-9xs: 4px;
  --br-45xl: 64px;
  --br-8xs: 5px;
  --br-5xs: 8px;

  /* Effects */
  --elevation-1: 0px 1px 3px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
}
