.signUp {
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
