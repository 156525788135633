.cardStateLayeroutlined,
.stateLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cardStateLayeroutlined {
  flex: 1;
  margin: 0 !important;
  border-radius: var(--br-xs);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  overflow: hidden;
  z-index: 0;
}
.mediaIcon,
.subhead,
.title {
  align-self: stretch;
  position: relative;
}
.mediaIcon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.subhead,
.title {
  letter-spacing: 0.5px;
  line-height: 24px;
}
.subhead {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--m3-sys-light-on-surface-variant);
}
.headline {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--components-input-text-size);
}
.labelText,
.supportingText {
  position: relative;
  line-height: 20px;
}
.supportingText {
  align-self: stretch;
  letter-spacing: 0.25px;
  color: var(--m3-sys-light-on-surface-variant);
}
.labelText {
  letter-spacing: 0.1px;
  font-weight: 500;
}
.secondaryButton,
.stateLayer1 {
  align-items: center;
  justify-content: center;
}
.stateLayer1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
}
.secondaryButton {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  display: none;
  flex-direction: column;
}
.actions,
.primaryButton {
  display: flex;
  align-items: center;
}
.primaryButton {
  border-radius: var(--br-81xl);
  background-color: var(--color-mediumslateblue-100);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  color: var(--m3-sys-light-on-primary);
}
.actions {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  color: var(--m3-sys-light-primary);
}
.mediaTextContent,
.textContent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.textContent {
  padding: var(--padding-base);
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.mediaTextContent {
  flex: 1;
  align-items: center;
}
.contentContainer,
.stackedCard {
  flex: 1;
  border-radius: var(--br-xs);
  display: flex;
}
.contentContainer {
  border: 1px solid var(--m3-sys-light-outline-variant);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.stackedCard {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  text-align: left;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-on-surface);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 420px) {
  .stackedCard {
    flex-direction: row;
    gap: var(--gap-0);
    align-items: flex-start;
    justify-content: flex-start;
    flex: unset;
    align-self: stretch;
  }
}
