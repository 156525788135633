.vectorIcon {
  position: absolute;
  height: 86.4%;
  width: 15.28%;
  top: 6.45%;
  right: 84.72%;
  bottom: 7.15%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.launchintro {
  position: absolute;
  top: 0;
  left: 69.57px;
}
.vectorParent {
  position: relative;
  width: 100%;
  height: 62px;
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--color-blueviolet);
  font-family: var(--m3-label-large);
}
