.stateLayerOverlay {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.overline {
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.headline,
.overline,
.supportingText {
  align-self: stretch;
  position: relative;
}
.headline {
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface);
}
.supportingText {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  display: none;
}
.content {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.trailingSupportingText {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  text-align: right;
  display: none;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.divider1 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  height: 1px;
}
.divider,
.listItemlistItem0Density {
  flex-direction: column;
  justify-content: center;
}
.divider {
  align-self: stretch;
  display: none;
  align-items: flex-start;
  z-index: 2;
}
.listItemlistItem0Density {
  background-color: var(--m3-sys-light-surface);
  width: 360px;
  height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant);
  font-family: var(--m3-label-large);
}
