.overline1,
.switch {
  position: relative;
}
.overline1 {
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.overline {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.headline,
.supportingText {
  align-self: stretch;
  position: relative;
}
.headline {
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface1);
}
.supportingText {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
}
.title {
  width: 615px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.overline3 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.started {
  width: 74px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headline2,
.headline3 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.headline3 {
  font-family: var(--components-helper-text);
}
.completed,
.headlineParent {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headlineParent {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface1);
}
.completed {
  width: 126px;
  flex-shrink: 0;
  flex-direction: column;
}
.headline4 {
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface1);
}
.embedcode {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: var(--components-helper-text);
}
.labelText {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base) var(--padding-3xs)
    var(--padding-xs);
  justify-content: center;
}
.button,
.stateLayer,
.tourList {
  display: flex;
  align-items: center;
}
.button {
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-primary1);
}
.tourList {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-ghostwhite-100);
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant1);
  font-family: var(--m3-label-large);
}
