.account {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.accountParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.handSelect21,
.yourProfile {
  position: relative;
}
.handSelect21 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.labelText {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-on-primary);
  text-align: center;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
    var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.header,
.stateLayer {
  display: flex;
  align-items: center;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.header {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-13xl);
}
.stateLayerOverlay {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.overline {
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.headline,
.overline,
.supportingText {
  align-self: stretch;
  position: relative;
}
.headline {
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface);
}
.supportingText {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  display: none;
}
.content1 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.trailingSupportingText {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  text-align: right;
  display: none;
}
.stateLayer1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.divider1 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  height: 1px;
}
.divider,
.listItemlistItem0Density {
  flex-direction: column;
  justify-content: center;
}
.divider {
  align-self: stretch;
  display: none;
  align-items: flex-start;
  z-index: 2;
}
.listItemlistItem0Density {
  background-color: var(--m3-sys-light-surface);
  width: 360px;
  height: 72px;
  display: flex;
  align-items: center;
  position: relative;
}
.mainStreet {
  margin: 0;
}
.content2 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.minHeightFix {
  position: relative;
  background-color: var(--m3-sys-light-surface);
  width: 8px;
  height: 64px;
  opacity: 0;
}
.stateLayer2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-xs) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.listItemlistItem0Density1 {
  background-color: var(--m3-sys-light-surface);
  width: 360px;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.button1,
.listItemlistItem0Density1,
.stateLayer3 {
  display: flex;
  align-items: center;
}
.stateLayer3 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.appWindow,
.content,
.desktopAccount {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.appWindow,
.desktopAccount {
  overflow: hidden;
  align-items: flex-start;
}
.appWindow {
  align-self: stretch;
  padding: var(--padding-31xl) var(--padding-31xl) var(--padding-3xs);
  gap: var(--gap-11xl);
}
.desktopAccount {
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  width: 100%;
  height: 1024px;
  text-align: left;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 420px) {
  .header {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
}
