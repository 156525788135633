.vectorIcon {
  position: relative;
  width: 193px;
  height: 30px;
}
.logo {
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.tabIcons {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboard {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.tabDashboard {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.tabIcons3 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.dashboard3 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;
}
.tabAccountChild {
  position: absolute;
  margin: 0 !important;
  top: 45px;
  left: -1px;
  border-top: 2px solid var(--color-mediumslateblue-100);
  box-sizing: border-box;
  width: 140px;
  height: 2px;
  z-index: 2;
}
.tabAccount,
.tabGroup {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.tabAccount {
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--color-mediumslateblue-200);
  padding: var(--padding-2xs) var(--padding-base);
  align-items: center;
  position: relative;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.tabGroup {
  align-items: flex-start;
}
.groupIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.hamburgerMenu,
.nav {
  overflow: hidden;
  flex-direction: row;
}
.hamburgerMenu {
  display: none;
  padding: 0 0 var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.nav {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-mediumslateblue-300);
  display: flex;
  padding: var(--padding-21xl) var(--padding-31xl) 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 960px) {
  .tabGroup {
    display: none;
  }
  .hamburgerMenu {
    display: flex;
  }
}
