.howToCreate {
  position: relative;
  line-height: 28px;
}
.closeicon {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.header {
  align-self: stretch;
  background-color: var(--m3-sys-light-on-primary);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-31xl);
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}
.textfield {
  border: 0;
  background-color: transparent;
}
.labelText {
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  font-family: var(--components-helper-text);
  color: var(--m3-sys-light-on-surface1);
  text-align: center;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
    var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.textfield1 {
  background-color: transparent;
}
.button {
  cursor: pointer;
  border: 1px solid var(--m3-sys-light-outline);
  padding: 0;
  border-radius: var(--br-81xl);
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.textfield1 {
  border: 0;
  align-self: stretch;
}
.addButtonToPage,
.controls {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
}
.controls {
  justify-content: flex-start;
  z-index: 1;
}
.labelText1 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--m3-sys-light-primary1);
}

.labelTextButton {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--m3-sys-light-primary1);
  cursor: pointer;
}

.helperText {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: -15px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.accordion {
  align-self: stretch;
}
.handleIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.body1 {
  flex: 1;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.iconbutton {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.accordionExpandIcon,
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.container {
  align-self: stretch;
  padding: 0 var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.body11 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 160%;
}
.typography {
  align-self: stretch;
  height: 49px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textfield2 {
  border: 0;
  background-color: transparent;
  flex: 1;
}
.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.select {
  flex: 1;
}
hr {
  width: 100%;
  margin: 0;
  border: 0;
}
.content2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  flex-flow: wrap;
}
.labelText2 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: var(--m3-label-large);
  color: var(--color-brown);
  text-align: right;
}
.mediaItem,
.removemedia {
  align-self: stretch;
  display: flex;
}
.removemedia {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xs);
}
.mediaItem {
  border-radius: var(--br-5xs);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid var(--color-whitesmoke);
  flex-direction: column;
  padding: var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.labelText3 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-primary1);
  text-align: left;
  display: inline-block;
}
.navigationPhaseForm {
  gap: var(--gap-5xl);
}
.navigation,
.navigationPhaseForm {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.labelText7 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: var(--m3-label-large);
  color: var(--color-brown);
  text-align: left;
}
.trashDeleteBin1Parent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  margin: auto;
}
.accordiondetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-base) var(--padding-base) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--typography-h6-size);
  font-family: var(--m3-label-large);
}
.accordionsummary {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--m3-sys-light-on-primary);
  box-shadow: var(--elevation-1);
}
.accordion1,
.accordionsummary,
.appWindow,
.content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.accordion1 {
  text-align: left;
  font-size: var(--components-input-text-size);
  color: var(--text-primary);
  font-family: var(--components-helper-text);
}
.appWindow,
.content {
  gap: var(--gap-5xs);
  z-index: 0;
}
.appWindow {
  flex: 1;
  overflow-y: scroll;
  flex-shrink: 0;
  padding: var(--padding-31xl) var(--padding-31xl) 100px;
  box-sizing: border-box;
  gap: var(--gap-11xl);
  z-index: 1;
  font-size: var(--m3-title-large-size);
}
.labelText8 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--color-mediumslateblue-100);
  text-align: center;
}
.stateLayer1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
    var(--padding-base);
  align-items: center;
  justify-content: center;
}
.button1,
.labelText9 {
  cursor: pointer;
  border: 0;
  padding: 0;
}
.button1 {
  background-color: var(--color-ghostwhite-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.labelText9 {
  background-color: transparent;
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--color-lavender-100);
  text-align: center;
  display: inline-block;
}
.buttonParent,
.saveChanges {
  display: flex;
  flex-direction: row;
}
.buttonParent {
  width: 1134px;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.saveChanges {
  align-self: stretch;
  background-color: var(--color-mediumslateblue-100);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  padding: var(--padding-5xl) var(--padding-31xl);
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
  width: 100%;
  animation: smooth-remove 1s ease forwards;
}
@keyframes smooth-appear {
  from {
    bottom: -150px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes smooth-remove {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -150px;
    opacity: 0;
  }
}
.hidden {
  display: none;
}
.saveAnim {
  animation: smooth-appear 1s ease forwards;
}
.desktopToursDetailView {
  position: relative;
  border-radius: 16px;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100%;
  overflow-y: clip;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  max-height: 100%;
  overflow: clip;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 420px) {
  .header,
  .saveChanges {
    flex-direction: column;
    gap: var(--gap-base);
    align-items: center;
    justify-content: flex-start;
  }
}
