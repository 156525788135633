.ellipseDiv,
.handleChild,
.handleChild1,
.handleChild2,
.handleInner,
.handleItem {
  position: absolute;
  top: 7px;
  left: 4px;
  border-radius: 50%;
  background-color: var(--color-darkslategray-100);
  width: 2px;
  height: 2px;
}
.ellipseDiv,
.handleChild1,
.handleChild2,
.handleInner,
.handleItem {
  top: 15px;
}
.ellipseDiv,
.handleChild1,
.handleChild2,
.handleInner {
  top: 7px;
  left: 11px;
}
.ellipseDiv,
.handleChild1,
.handleChild2 {
  top: 15px;
}
.handleChild1,
.handleChild2 {
  top: 7px;
  left: 18px;
}
.handleChild2 {
  top: 15px;
}
.handle {
  position: relative;
  width: 100%;
  height: 24px;
  overflow: hidden;
}
