.slide1 {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--switch-slidefill);
  width: 34px;
  height: 14px;
}
.slide {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: flex-start;
  opacity: 0.38;
}
.knob1 {
  position: relative;
  border-radius: 50%;
  background-color: var(--switch-knobfill);
  box-shadow: var(--elevation-1);
  width: 20px;
  height: 20px;
}
.knob {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
}
.label,
.switch1 {
  position: relative;
}
.switch1 {
  width: 58px;
  height: 38px;
}
.label {
  letter-spacing: 0.15px;
  line-height: 150%;
  display: none;
}
.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--components-input-text-size);
  color: var(--text-primary);
  font-family: var(--components-helper-text);
}
.numberOfSteps {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.overline {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.description {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  display: none;
}
.title {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.overline1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.headline {
  align-self: stretch;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface1);
}
.headline,
.headline1,
.headline2 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.headline2 {
  font-family: var(--components-helper-text);
}
.analytics,
.headlineParent {
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
}
.headlineParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface1);
}
.analytics {
  width: 216px;
  flex-shrink: 0;
  display: none;
  align-items: center;
  gap: var(--gap-base);
}
.embedcodeval {
  position: relative;
  font-size: var(--components-input-text-size - 2);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface1);
}
.embedcode {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: var(--components-helper-text);
}
.labelText {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base) var(--padding-3xs)
    var(--padding-xs);
  justify-content: center;
}
.button,
.stateLayer,
.tourListItem {
  display: flex;
  align-items: center;
}
.button {
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-primary1);
}
.tourListItem {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-ghostwhite-100);
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant1);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 960px) {
  .embedcode {
    display: none;
  }
  .tourListItem {
    flex-direction: row;
  }
}
@media screen and (max-width: 420px) {
  .embedcode {
    display: none;
  }
}
