.justAFew,
.letsGetStarted {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.25px;
  line-height: 64px;
}
.justAFew {
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
}
.title {
  width: 435px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  text-align: center;
}
.userId {
  border: 0;
  background-color: transparent;
}
.rememberMe1,
.switch {
  position: relative;
}
.rememberMe1 {
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.rememberMe {
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) 0 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--m3-label-large-size);
}
.promotionRocket1 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.labelText {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-on-primary);
  text-align: center;
}
.button,
.stateLayer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
    var(--padding-base);
  gap: var(--gap-5xs);
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
}
.alreadyHaveAn,
.labelText1 {
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
}
.labelText1 {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--color-mediumslateblue-100);
  text-align: center;
}
.button1,
.inputs,
.stateLayer1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer1 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
}
.button1,
.inputs {
  flex-direction: column;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputs {
  width: 928px;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--m3-display-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
