.handSelect21,
.introkitio {
  position: relative;
  height: 50px;
}
.handSelect21 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.labelText {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-on-primary);
  text-align: center;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
    var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.nav {
  overflow: hidden;
  display: flex;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav {
  margin: 0;
  background: linear-gradient(rgba(130, 82, 209, 0.1), rgba(130, 82, 209, 0.1)),
    #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-31xl);
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
.content,
.header,
.nav {
  align-self: stretch;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  height: 300px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.welcome {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.icon {
  position: relative;
  width: 18px;
  height: 18px;
}
.button1,
.header1 {
  display: flex;
  align-items: center;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.header1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.slide1 {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--color-mediumslateblue-100);
  width: 34px;
  height: 14px;
}
.slide {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: flex-start;
  opacity: 0.5;
}
.knob1 {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-mediumslateblue-100);
  box-shadow: var(--elevation-1);
  width: 20px;
  height: 20px;
}
.knob {
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
}
.label,
.switch1 {
  position: relative;
}
.switch1 {
  width: 58px;
  height: 38px;
}
.label {
  letter-spacing: 0.15px;
  line-height: 150%;
  display: none;
}
.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--components-input-text-size);
  color: var(--text-primary);
  font-family: var(--components-helper-text);
}
.numberOfSteps {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.overline {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.description {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  display: none;
}
.title {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.overline1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.headline {
  align-self: stretch;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface1);
}
.headline,
.headline1,
.headline2 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.headline2 {
  font-family: var(--components-helper-text);
}
.analytics,
.headlineParent {
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
}
.headlineParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface1);
}
.analytics {
  width: 216px;
  flex-shrink: 0;
  display: none;
  align-items: center;
  gap: var(--gap-base);
}
.embedcodeval {
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface1);
}
.embedcode {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: var(--components-helper-text);
}
.labelText2 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.stateLayer2 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base) var(--padding-3xs)
    var(--padding-xs);
  justify-content: center;
}
.button2,
.stateLayer2,
.tourListItem {
  display: flex;
  align-items: center;
}
.button2 {
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-primary1);
}
.tourListItem {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-ghostwhite-100);
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
}
.slide3 {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--switch-slidefill);
  width: 34px;
  height: 14px;
}
.slide2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: flex-start;
  opacity: 0.38;
}
.knob3 {
  position: relative;
  border-radius: 50%;
  background-color: var(--switch-knobfill);
  box-shadow: var(--elevation-1);
  width: 20px;
  height: 20px;
}
.knob2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
}
.description1 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  display: none;
  width: 615px;
}
.button3,
.tourListitem {
  display: flex;
  align-items: center;
}
.button3 {
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-primary1);
}
.tourListitem {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-ghostwhite-100);
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
}
.header2,
.toursList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.toursList {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant1);
}
.header2 {
  width: 1340px;
  justify-content: center;
}
.cardStateLayeroutlined,
.stateLayer5 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cardStateLayeroutlined {
  flex: 1;
  margin: 0 !important;
  border-radius: var(--br-xs);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  overflow: hidden;
  z-index: 0;
}
.mediaIcon,
.subhead,
.title4 {
  align-self: stretch;
  position: relative;
}
.mediaIcon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.subhead,
.title4 {
  letter-spacing: 0.5px;
  line-height: 24px;
}
.subhead {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--m3-sys-light-on-surface-variant);
}
.headline13 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.labelText5 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-primary);
  text-align: center;
}
.stateLayer6 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
  align-items: center;
  justify-content: center;
}
.primaryButton,
.secondaryButton {
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.secondaryButton {
  border: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
  display: none;
}
.primaryButton {
  background-color: var(--color-mediumslateblue-100);
  display: flex;
}
.actions,
.textContent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.actions {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex-direction: row;
  justify-content: space-between;
}
.textContent {
  flex-direction: column;
  padding: var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.contentContainer,
.mediaTextContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mediaTextContent {
  align-self: stretch;
  justify-content: flex-start;
}
.contentContainer {
  border-radius: var(--br-xs);
  border: 1px solid var(--m3-sys-light-outline-variant);
  overflow: hidden;
  justify-content: center;
  z-index: 1;
}
.headline14,
.stackedCard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.stackedCard {
  flex: 1;
  border-radius: var(--br-xs);
  flex-direction: row;
  position: relative;
}
.headline14 {
  align-self: stretch;
  flex-direction: column;
  font-size: var(--components-input-text-size);
}
.supportingText1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--m3-sys-light-on-surface-variant);
}
.primaryButton1 {
  border-radius: var(--br-81xl);
  background-color: var(--color-mediumslateblue-100);
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--m3-sys-light-on-primary);
}
.actions1,
.stackedCard1 {
  flex-direction: row;
  align-items: flex-start;
}
.actions1 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: var(--m3-sys-light-primary);
}
.stackedCard1 {
  flex: 1;
  border-radius: var(--br-xs);
  position: relative;
  font-size: var(--m3-label-large-size);
}
.content1,
.contentWrapper,
.stackedCard1,
.toursList1 {
  display: flex;
  justify-content: flex-start;
}
.content1 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-base);
}
.contentWrapper,
.toursList1 {
  flex-direction: column;
}
.contentWrapper {
  align-self: stretch;
  overflow: hidden;
  padding: 0 0 var(--padding-sm);
  align-items: center;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface);
}
.toursList1 {
  align-items: flex-start;
  gap: var(--gap-base);
}
.header3,
.toursList1,
.wiki {
  align-self: stretch;
}
.wiki {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.header3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stateLayerOverlay {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.headline16 {
  align-self: stretch;
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface);
}
.content3 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.trailingSupportingText {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  text-align: right;
  display: none;
}
.stateLayer15 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.divider1 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  height: 1px;
}
.divider {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
}
.listItemlistItem0Density,
.stateLayer16 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.listItemlistItem0Density {
  background-color: var(--m3-sys-light-surface);
  height: 72px;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.stateLayer16 {
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.mainStreet {
  margin: 0;
}
.minHeightFix {
  position: relative;
  background-color: var(--m3-sys-light-surface);
  width: 8px;
  height: 64px;
  opacity: 0;
}
.listItemlistItem0Density2,
.stateLayer17 {
  align-self: stretch;
  justify-content: flex-start;
}
.stateLayer17 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-xs) var(--padding-base);
  align-items: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.listItemlistItem0Density2 {
  background-color: var(--m3-sys-light-surface);
  flex-direction: column;
  position: relative;
}
.button6,
.listItemlistItem0Density2,
.stateLayer18 {
  display: flex;
  align-items: center;
}
.stateLayer18 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.button6 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.listItemlistItem0DensityParent {
  width: 360px;
  overflow: hidden;
  align-items: center;
  gap: var(--gap-5xs);
}
.appWindow,
.content2,
.desktopDashboard,
.listItemlistItem0DensityParent,
.myProfile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content2 {
  align-self: stretch;
  align-items: center;
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.appWindow,
.desktopDashboard,
.myProfile {
  align-items: flex-start;
}
.myProfile {
  align-self: stretch;
  padding: 0 0 var(--padding-31xl);
  gap: var(--gap-11xl);
}
.appWindow,
.desktopDashboard {
  overflow: hidden;
}
.appWindow {
  align-self: stretch;
  padding: var(--padding-31xl) var(--padding-31xl) var(--padding-3xs);
  gap: 50px;
  z-index: 0;
}
.desktopDashboard {
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  width: 100%;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 1200px) {
  .tourListitem {
    flex-direction: row;
    gap: var(--gap-base);
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 960px) {
  .embedcode {
    display: none;
  }
  .tourListitem {
    flex-direction: row;
    gap: var(--gap-5xs);
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 420px) {
  .embedcode {
    display: none;
  }
  .content1,
  .header3,
  .stackedCard,
  .stackedCard1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .stackedCard,
  .stackedCard1 {
    flex-direction: row;
    gap: var(--gap-0);
    flex: unset;
    align-self: stretch;
  }
  .content1,
  .header3 {
    flex-direction: column;
    gap: var(--gap-base);
  }
  .header3 {
    gap: var(--gap-3xs);
  }
}
@media screen and (max-width: 350px) {
  .button {
    display: none;
  }
}
