.vectorIcon {
  position: relative;
  width: 193px;
  height: 30px;
}
.logo {
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.tabIcons {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboard {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.tabDashboard {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.tabIcons1 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.dashboard1 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;
}
.tabToursChild {
  position: absolute;
  margin: 0 !important;
  top: 45px;
  left: -1px;
  border-top: 2px solid var(--color-mediumslateblue-100);
  box-sizing: border-box;
  width: 140px;
  height: 2px;
  z-index: 2;
}
.tabGroup,
.tabTours {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.tabTours {
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--color-mediumslateblue-200);
  padding: var(--padding-2xs) var(--padding-base);
  align-items: center;
  position: relative;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.tabGroup {
  align-items: flex-start;
}
.groupIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.hamburgerMenu,
.nav {
  overflow: hidden;
  flex-direction: row;
}
.hamburgerMenu {
  display: none;
  padding: 0 0 var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.nav {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-mediumslateblue-300);
  display: flex;
  padding: var(--padding-21xl) var(--padding-31xl) 0;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
.tours {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.toursParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.allTours,
.icon {
  position: relative;
}
.icon {
  width: 18px;
  height: 18px;
}
.labelText {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-on-primary);
  text-align: center;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
    var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.header,
.stateLayer {
  display: flex;
  align-items: center;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-mediumslateblue-100);
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.header {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-13xl);
}
.overline1 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.overline {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.headline {
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  display: flex;
  color: var(--m3-sys-light-on-surface1);
  align-items: center;
  width: 615px;
}
.supportingText {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
}
.title {
  width: 615px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headline1,
.overline3 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
}
.overline3 {
  line-height: 16px;
  font-weight: 500;
}
.headline1 {
  font-size: var(--components-input-text-size);
  line-height: 24px;
  color: var(--m3-sys-light-on-surface1);
}
.started {
  width: 74px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headline2,
.headline3 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.headline3 {
  font-family: var(--components-helper-text);
}
.completed,
.headlineParent {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headlineParent {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface1);
}
.completed {
  width: 126px;
  flex-shrink: 0;
  flex-direction: column;
}
.headline4 {
  position: relative;
  font-size: var(--components-input-text-size);
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--m3-sys-light-on-surface1);
}
.embedcode {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: var(--components-helper-text);
}
.stateLayer1 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base) var(--padding-3xs)
    var(--padding-xs);
  justify-content: center;
}
.button1,
.stateLayer1,
.tourList {
  display: flex;
  align-items: center;
}
.button1 {
  border-radius: var(--br-81xl);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-primary1);
}
.tourList {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-ghostwhite-100);
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-base);
}
.appWindow,
.content,
.desktopTours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  gap: var(--gap-5xs);
  font-size: var(--components-helper-text-size);
  color: var(--m3-sys-light-on-surface-variant1);
}
.appWindow,
.desktopTours {
  overflow: hidden;
}
.appWindow {
  align-self: stretch;
  padding: var(--padding-31xl) var(--padding-31xl) var(--padding-3xs);
  gap: var(--gap-11xl);
}
.desktopTours {
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  width: 100%;
  height: 1024px;
  text-align: left;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 1200px) {
  .tourList {
    flex-direction: row;
    gap: var(--gap-base);
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 960px) {
  .tabGroup {
    display: none;
  }
  .hamburgerMenu {
    display: flex;
  }
  .tourList {
    flex-direction: row;
    gap: var(--gap-5xs);
    align-items: center;
    justify-content: flex-start;
  }
}
