.vectorIcon {
  position: relative;
  width: 193px;
  height: 30px;
}
.logo {
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.tabIcons {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboard {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.tabDashboard {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.tabIcons2 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.dashboard2 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;
}
.tabInformationChild {
  position: absolute;
  margin: 0 !important;
  top: 45px;
  left: -1px;
  border-top: 2px solid var(--color-mediumslateblue-100);
  box-sizing: border-box;
  width: 140px;
  height: 2px;
  z-index: 2;
}
.tabGroup,
.tabInformation {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.tabInformation {
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--color-mediumslateblue-200);
  padding: var(--padding-2xs) var(--padding-base);
  align-items: center;
  position: relative;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.tabGroup {
  align-items: flex-start;
}
.groupIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.hamburgerMenu,
.nav {
  overflow: hidden;
  flex-direction: row;
}
.hamburgerMenu {
  display: none;
  padding: 0 0 var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.nav {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-mediumslateblue-300);
  display: flex;
  padding: var(--padding-21xl) var(--padding-31xl) 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-align: center;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
.information {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.informationParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.information1 {
  position: relative;
}
.header {
  width: 1340px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-size-13xl);
}
.cardStateLayeroutlined,
.stateLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cardStateLayeroutlined {
  flex: 1;
  margin: 0 !important;
  border-radius: var(--br-xs);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  overflow: hidden;
  z-index: 0;
}
.mediaIcon,
.subhead,
.title {
  align-self: stretch;
  position: relative;
}
.mediaIcon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.subhead,
.title {
  letter-spacing: 0.5px;
  line-height: 24px;
}
.subhead {
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--m3-sys-light-on-surface-variant);
}
.headline {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.labelText {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-primary);
  text-align: center;
}
.secondaryButton,
.stateLayer1 {
  align-items: center;
  justify-content: center;
}
.stateLayer1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
}
.secondaryButton {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  display: none;
  flex-direction: column;
}
.labelText1 {
  position: relative;
  font-size: var(--m3-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--m3-label-large);
  color: var(--m3-sys-light-on-primary);
  text-align: center;
}
.actions,
.primaryButton {
  display: flex;
  align-items: center;
}
.primaryButton {
  border-radius: var(--br-81xl);
  background-color: var(--color-mediumslateblue-100);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.actions {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.mediaTextContent,
.textContent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.textContent {
  padding: var(--padding-base);
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.mediaTextContent {
  flex: 1;
  align-items: center;
}
.contentContainer,
.stackedCard {
  flex: 1;
  border-radius: var(--br-xs);
  display: flex;
}
.contentContainer {
  border: 1px solid var(--m3-sys-light-outline-variant);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.stackedCard {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.cardStateLayeroutlined1 {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
  overflow: hidden;
  z-index: 0;
}
.headline1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--components-input-text-size);
}
.supportingText1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--m3-sys-light-on-surface-variant);
}
.actions1,
.primaryButton1 {
  display: flex;
  align-items: center;
}
.primaryButton1 {
  border-radius: var(--br-81xl);
  background-color: var(--color-mediumslateblue-100);
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  color: var(--m3-sys-light-on-primary);
}
.actions1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  color: var(--m3-sys-light-primary);
}
.stackedCard1,
.stackedCard2 {
  flex: 1;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.stackedCard2 {
  z-index: 1;
}
.stackedCard1 {
  height: 480px;
  font-size: var(--m3-label-large-size);
}
.content {
  align-self: stretch;
  flex-wrap: wrap;
  gap: var(--gap-base);
}
.content,
.content1,
.stackedCard3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.stackedCard3 {
  flex: 1;
  border-radius: var(--br-xs);
  height: 480px;
  position: relative;
}
.content1 {
  align-self: stretch;
  flex-wrap: wrap;
  gap: var(--gap-base);
  font-size: var(--m3-label-large-size);
}
.appWindow,
.contentParent,
.desktopInformation {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contentParent {
  align-self: stretch;
  height: 990px;
  flex-shrink: 0;
  padding: 0 0 var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-base);
  font-size: var(--components-input-text-size);
  color: var(--m3-sys-light-on-surface);
}
.appWindow,
.desktopInformation {
  align-items: flex-start;
}
.appWindow {
  align-self: stretch;
  padding: var(--padding-31xl) var(--padding-31xl) var(--padding-3xs);
  gap: var(--gap-11xl);
}
.desktopInformation {
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  width: 100%;
  text-align: left;
  font-size: var(--m3-label-large-size);
  color: var(--switch-slidefill);
  font-family: var(--m3-label-large);
}
@media screen and (max-width: 960px) {
  .tabGroup {
    display: none;
  }
  .hamburgerMenu {
    display: flex;
  }
}
@media screen and (max-width: 420px) {
  .stackedCard {
    flex: unset;
    align-self: stretch;
  }
  .stackedCard,
  .stackedCard1,
  .stackedCard2 {
    flex-direction: row;
    gap: var(--gap-0);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .stackedCard1 {
    flex: unset;
    align-self: stretch;
  }
  .content {
    flex-direction: column;
    gap: var(--gap-base);
  }
  .content,
  .content1,
  .stackedCard3 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .stackedCard3 {
    flex-direction: row;
    gap: var(--gap-0);
    flex: unset;
    align-self: stretch;
  }
  .content1 {
    flex-direction: column;
    gap: var(--gap-base);
  }
}
